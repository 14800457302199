.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: $m-x40;

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: $c-blue;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__filter-btn,
  &__add-btn {
    display: inline-flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    outline: none;
    transition: all .3s;

    &:not(:last-child) {
      margin-right: $m-x16;
    }

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      margin-right: $m-x16;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.icon-only:before {
      margin-right: 0;
    }

    &:hover {
      transition: all .3s;
    }
  }

  &__filter-btn {
    width: auto;
    background-color: $c-white;
    color: $c-orange;

    &:before {
      background-image: url('/assets/img/icon-filter.svg');
      transition: all .3s;
    }

    &:hover {
      background-color: $c-orange;
      color: $c-white;

      &:before {
        background-image: url('/assets/img/icon-filter-white.svg');
        transition: all .3s;
      }

      strong {
        background-color: $c-white;
        color: $c-orange;
        transition: all .3s;
      }
    }

    strong {
      display: inline-block;
      width: auto;
      height: auto;
      margin-left: 16px;
      padding: 3px 5px;
      border-radius: 100px;
      background-color: $c-orange;
      font-weight: normal;
      font-size: 10px;
      line-height: 10px;
      color: #fff;
      text-align: center;
      transition: all .3s;
    }
  }

  &__add-btn {
    width: auto;
    background-color: $c-blue;
    color: $c-white;

    &:before {
      background-image: url('/assets/img/icon-add.svg');
    }

    &:hover {
      background-color: $c-orange;
    }
  }
}
