body .table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__showing {
    display: flex;
    align-items: center;

    @media(max-width: 768px) {
      position: absolute;
      top: 8em;
      right: 2em;
      align-items: center;
    }
  }

  &__showing-name {
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
  }

  &__showing-select {
    width: 72px;
    height: 32px;
    margin: 0 $m-x16;

    .ngx-select__toggle {
      position: relative;
      width: 72px;
      height: 32px;
      padding: 0 15px;
      border-radius: 16px;
      border-color: transparent !important;
      background-color: #dfeeff !important;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: $c-orange !important;
      outline: none !important;
    }

    .dropdown-menu {
      top: auto;
      bottom: 100%;
      width: 72px;
      min-width: 72px;
      border-radius: 16px;
      background-color: #dfeeff !important;
      text-align: center;
      outline: none;

      @media(max-width: 768px) {
        top: 100%;
        bottom: auto;
      }

      li {
        padding: 0;
      }
    }

    .ngx-select__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 3px 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: $c-orange !important;
    }

    .ngx-select__item_active {
      background-color: #154c8e !important;
      color: $c-white !important;
    }

    .ngx-select {
      outline: none !important;
    }

    .dropdown-toggle:after {
      width: 5px;
      height: 5px;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: none;
      border-right: 1px solid $c-orange;
      border-bottom: 1px solid $c-orange;
      border-left: none;
      transform: rotate(45deg);
      transition: .3s;
    }

    .open.show .dropdown-toggle:after {
      vertical-align: 0.1em;
      transform: rotate(-135deg);
      transition: .3s;
    }
  }

  &__showing-counter {
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
  }

  .ngx-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin: 0 $m-x8;
      border: none;
      border-radius: 50%;
      overflow: hidden;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #e5e5e5;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #8f8f8f;
        text-decoration: none;
        text-align: center;
        transition: all .3s;
        &:hover {
          background-color: $c-orange;
          color: $c-white;
          transition: all .3s;
        }
      }
    }

    li.current {
      background-color: $c-orange;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: $c-white;
      transition: all .3s;
    }

    li.pagination-next,
    li.pagination-previous {
      position: relative;
      width: 32px;
      height: 32px;
      margin: 0 $m-x8;
      border: none;
      border-radius: 50%;
      background-color: #e5e5e5;
      font-size: 0;
      cursor: pointer;
      outline: none;
      transition: all .3s;
      & a:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: 0;
        border-top: 2px solid #8f8f8f;
        border-left: 2px solid #8f8f8f;
        border-radius: 1px;
        z-index: -1;
        transition: all .3s;
      }
      &:hover {
        background-color: $c-orange;
        transition: all .3s;
        & a:after {
          border-top: 2px solid $c-white;
          border-left: 2px solid $c-white;
          transition: all .3s;
        }
      }

      a {
        position: relative;
        font-size: inherit;
        color: transparent;
        z-index: 1;
      }

      &.disabled {
        opacity: .5;
        cursor: default;
        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          margin: 0;
          border-top: 2px solid #8f8f8f;
          border-left: 2px solid #8f8f8f;
          border-radius: 1px;
          z-index: -1;
          transition: all .3s;
        }
        &:hover {
          background-color: #e5e5e5;
          &:after {
            border-top: 2px solid #8f8f8f;
            border-left: 2px solid #8f8f8f;
          }
        }
      }
    }

    li.pagination-previous {
      & a:after {
        transform: translate(-25%, -50%) rotate(-45deg);
      }
      &.disabled:after {
        transform: translate(-25%, -50%) rotate(-45deg);
      }
    }

    li.pagination-next {
      & a:after {
        transform: translate(-75%, -50%) rotate(135deg);
      }
      &.disabled:after {
        transform: translate(-75%, -50%) rotate(135deg);
      }
    }

    li.small-screen {
      display: none;
    }
  }

  &__pagin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__pagin-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 $m-x8;
    border: none;
    border-radius: 50%;
    overflow: hidden;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
      background-color: #e5e5e5;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: #8f8f8f;
      text-align: center;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        background-color: $c-orange;
        color: $c-white;
        transition: all .3s;
      }

      &.active {
        background-color: $c-orange;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: $c-white;
        transition: all .3s;
      }
    }
  }

  &__pagin-prev,
  &__pagin-next {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 $m-x8;
    border: none;
    border-radius: 50%;
    background-color: #e5e5e5;
    font-size: 0;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    transition: all .3s;
    & button:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin: 0;
      border-top: 2px solid #8f8f8f;
      border-left: 2px solid #8f8f8f;
      border-radius: 1px;
      z-index: -1;
      transition: all .3s;
    }
    &:hover button {
      background-color: $c-orange;
      &:after {
        border-top: 2px solid $c-white;
        border-left: 2px solid $c-white;
        transition: all .3s;
      }
    }

    button {
      position: relative;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      font-size: inherit;
      color: transparent;
      cursor: pointer;
      z-index: 1;
    }

    button[disabled] {
      opacity: .5;
      cursor: default;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: 0;
        border-top: 2px solid #8f8f8f;
        border-left: 2px solid #8f8f8f;
        border-radius: 1px;
        z-index: -1;
        transition: all .3s;
      }
      &:hover {
        background-color: #e5e5e5;
        &:after {
          border-top: 2px solid #8f8f8f;
          border-left: 2px solid #8f8f8f;
        }
      }
    }
  }

  &__pagin-prev {
    & button:after {
      transform: translate(-25%, -50%) rotate(-45deg);
    }
    &.disabled:after {
      transform: translate(-25%, -50%) rotate(-45deg);
    }
  }

  &__pagin-next {
    & button:after {
      transform: translate(-75%, -50%) rotate(135deg);
    }
    &.disabled:after {
      transform: translate(-75%, -50%) rotate(135deg);
    }
  }

  ngb-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @media(max-width: 768px) {
      width: 100%;
    }

    .page-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin: 0 $m-x8;
      border: none;
      border-radius: 50%;
      overflow: hidden;

      &.disabled {
        opacity: .5;
        cursor: default;
        &:hover {
          background-color: #e5e5e5;
        }
      }
    }

    .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
      background-color: #e5e5e5;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: #8f8f8f;
      text-decoration: none;
      text-align: center;
      transition: all .3s;
      &:hover {
        background-color: $c-orange;
        color: $c-white;
        transition: all .3s;
      }

      span {
        display: none;
      }
    }

    .page-item.active .page-link {
      border: none;
      background-color: $c-orange;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: $c-white;
      transition: all .3s;
    }

    .page-item:first-child,
    .page-item:last-child {
      position: relative;
      width: 32px;
      height: 32px;
      margin: 0 $m-x8;
      border: none;
      border-radius: 50%;
      background-color: #e5e5e5;
      font-size: 0;
      cursor: pointer;
      outline: none;
      transition: all .3s;
      & .page-link:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: 0;
        border-top: 2px solid #8f8f8f;
        border-left: 2px solid #8f8f8f;
        border-radius: 1px;
        z-index: -1;
        transition: all .3s;
      }
      &:hover {
        background-color: $c-orange;
        transition: all .3s;
        & .page-link:after {
          border-top: 2px solid $c-white;
          border-left: 2px solid $c-white;
          transition: all .3s;
        }
      }

      .page-link {
        position: relative;
        font-size: inherit;
        color: transparent;
        z-index: 1;
      }

      &.disabled {
        opacity: .5;
        cursor: default;
        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          margin: 0;
          border-top: 2px solid #8f8f8f;
          border-left: 2px solid #8f8f8f;
          border-radius: 1px;
          z-index: -1;
          transition: all .3s;
        }
        &:hover {
          background-color: #e5e5e5;
          & .page-link:after {
            border-top: 2px solid #8f8f8f;
            border-left: 2px solid #8f8f8f;
            transition: all .3s;
          }
        }

        .page-link {
          background-color: #e5e5e5;
        }
      }
    }

    .page-item:first-child {
      & .page-link:after {
        transform: translate(-25%, -50%) rotate(-45deg);
      }
      &.disabled:after {
        transform: translate(-25%, -50%) rotate(-45deg);
      }
    }

    .page-item:last-child {
      & .page-link:after {
        transform: translate(-75%, -50%) rotate(135deg);
      }
      &.disabled:after {
        transform: translate(-75%, -50%) rotate(135deg);
      }
    }
  }
}
