.avtable {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: $m-x32;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  &__table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #cacaca;
  }

  &__table-title {
    flex: 0 1 100%;
    margin: 0;
    padding-left: $p-x16;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $c-blue;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    span {
      color: $c-orange;
      visibility: hidden;
    }

    &.active {
      span {
        visibility: visible;
      }
    }

    &--new {
      max-width: 15px;
    }

    &--small {
      max-width: 85px;
    }

    &--big {
      min-width: 25%;
    }
  }

  &__table-items {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__table-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 60px;
    border-top: 1px solid #e5e5e5;
    background-color: transparent;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: #f2f2f2;
      transition: all .3s;
    }

    &--new {
      background-color: #fff1eb;
    }
  }

  &__item-cell {
    display: inline-block;
    flex: 0 1 100%;
    width: auto;
    padding-left: $p-x16;
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--new {
      position: relative;
      max-width: 15px;
      padding: 0;

      img {
        width: 12px;
        height: 45px;
        object-fit: contain;
        object-position: left center;
      }
    }

    &--small {
      max-width: 85px;
    }

    &--big {
      min-width: 25%;

      .avtable__item-name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__item-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 38px;
    padding: 10px 14px;
    border-radius: 19px;
    background-color: #f2f2f2;
    font-size: 14px;
    line-height: 14px;
    color: #8f8f8f;
    text-transform: uppercase;
    text-decoration: none;
    transition: all .3s;
    &:hover {
      background-color: #DFEDFF;
      color: $c-blue;
      text-decoration: underline;
      transition: all .3s;
    }
  }

  &__item-name {
    display: block;
    margin-bottom: calc($m-x8 / 2);
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
  }

  &__item-position {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
  }

  &__status {
    display: inline-block;
    flex-shrink: 0;
    width: 67px;
    height: 28px;
    padding: 0 10px;
    border-radius: 14px;
    background-color: #cacaca;
    font-weight: bold;
    font-size: 10px;
    line-height: 28px;
    text-transform: uppercase;
    color: $c-white;
    text-align: center;
    transition: all .3s;

    &--active {
      width: 67px;
      background-color: #75d579;
      transition: all .3s;
    }
  }

  &__route {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 38px;
    padding: 10px 16px;
    border-radius: 19px;
    background-color: #f2f2f2;
    transition: all .3s;
    &:hover {
      background-color: #DFEDFF;
      color: $c-blue;
      text-decoration: underline;
      transition: all .3s;
    }
  }

  &__small-status {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: #fff;
    transition: all .3s;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: transparent;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }

    &--close {
      border: 2px solid #cacaca;
      transition: all .3s;
      &:after {
        background-color: #cacaca;
        transition: all .3s;
      }
    }

    &--success {
      border: 2px solid #75d579;
      transition: all .3s;
      &:after {
        background-color: #75d579;
        transition: all .3s;
      }
    }

    &--warning {
      border: 2px solid #F2C94C;
      transition: all .3s;
      &:after {
        background-color: #F2C94C;
        transition: all .3s;
      }
    }

    &--danger {
      border: 2px solid #EB5757;
      transition: all .3s;
      &:after {
        background-color: #EB5757;
        transition: all .3s;
      }
    }
  }

  &__working-hours {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url('/assets/img/icon-workin-grey.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    transition: all .3s;

    &--active {
      background-image: url('/assets/img/icon-workin.svg');
      transition: all .3s;
    }
  }
}
