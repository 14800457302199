.spm-alerts {
  &__alert-success {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 50%;
    width: 308px;
    height: 80px;
    padding: 0 40px 0 60px;
    border: 1px solid #79ce81;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    text-align: center;
    z-index: 9000;
    transform: translate(-50%, 0);
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
    }
    &:before {
      left: 16px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #79ce81;
      transform: translate(0, -50%);
    }
    &:after {
      left: 26px;
      width: 19px;
      height: 10px;
      border-left: 3px solid #fff;
      border-bottom: 3px solid #fff;
      transform: translate(0, -75%) rotate(-45deg);
    }
  }

  &__alert-error {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 50%;
    width: 350px;
    height: 80px;
    padding: 0 30px 0 60px;
    border: 1px solid #ff5a5a;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    text-align: center;
    z-index: 10000;
    transform: translate(-50%, 0);
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
    }
    &:before {
      left: 16px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ff5a5a;
      transform: translate(0, -50%);
    }
    &:after {
      left: 24px;
      width: 25px;
      height: 25px;
      background-image: url('/assets/img/alert-cancel.svg');
      background-position: 50%;
      background-repeat: no-repeat;
      transform: translate(0, -50%);
    }
  }

  &_cancel {
    position: absolute;
    top: 4px;
    right: 4px;
    display: block;
    width: 15px;
    height: 15px;
    font-size: 0;
    color: transparent;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: #808080;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #ff5a5a;
        transition: all .3s;
      }
    }
  }

  &__alert-confirm {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 50%;
    width: 560px;
    height: 160px;
    padding: 20px 60px;
    border: 1px solid #ffc01e;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    text-align: center;
    z-index: 9000;
    transform: translate(-50%, 0);

    span {
      display: block;
      width: 100%;
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 28px;
      color: #808080;
    }

    .button {
      width: 168px;
      height: 40px;
      margin: 0 12px;
    }
  }

  &__alert-lang {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 50%;
    width: 560px;
    height: 160px;
    padding: 20px 60px;
    border: 1px solid #ffc01e;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    text-align: center;
    z-index: 9000;
    transform: translate(-50%, 0);

    p {
      width: 100%;
      margin-bottom: 24px;
      padding: 0 60px;
      font-size: 24px;
      line-height: 28px;
      color: #808080;
    }

    .lang-switcher {
      margin: auto;
      cursor: default;
    }
  }

  &__alert-prompt {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 50%;
    width: 560px;
    height: auto;
    padding: 20px 60px;
    border: 1px solid #ff5a5a;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 24px;
    line-height: 28px;
    color: #808080;
    text-align: center;
    z-index: 9000;
    transform: translate(-50%, 0);
  }
}

.modal-backdrop {
  opacity: 1;
  background-color: rgba(255, 255, 255, .75);

  &.show,
  &.fade {
    background-color: rgba(255, 255, 255, .75);
  }

  &.show {
    opacity: 1;
  }
}

.modal-delete {
  display: flex;
  flex-direction: column;
  width: 560px;
  max-width: 100%;
  height: auto;
  padding: $p-x24 $p-x24 $p-x40;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

  .modal-content {
    position: static;
    border: none;
    border-radius: 0;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $m-x40;
  }

  &__title {
    margin: 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #EB5757;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: #8F8F8F;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #EB5757;
        transition: all .3s;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn-del,
  &__btn-cancel {
    flex: 0 1 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      transition: all .3s;
    }
  }

  &__btn-del {
    margin-right: 30px;
    background-color: $c-orange;
    color: #fff;
    &:hover {
      background-color: $c-blue;
    }
  }

  &__btn-cancel {
    background-color: #fff;
    color: #8f8f8f;
    &:hover {
      background-color: #8f8f8f;
      color: #fff;
    }
  }
}

.modal-eobt {
  width: fit-content;
  top: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: .25em;

  .modal-content {
    max-width: 328px;
    position: static;
    border: none;
  }
}

.modal-fee {
  display: flex;
  flex-direction: column;
  width: 920px;
  max-width: 100%;
  height: auto;
  padding: $p-x24 $p-x40 $p-x40;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

  .modal-content {
    position: static;
    border: none;
    border-radius: 0;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $m-x40;
  }

  &__title {
    margin: 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #EB5757;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: #8F8F8F;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #EB5757;
        transition: all .3s;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: $m-x16;
    padding-left: .25em;

    [type=text] {
      width: 100%;
      height: 40px;
      border: 1px solid #CACACA;
      border-radius: 4px;
      font-size: 14px;
      line-height: 40px;
      color: #8F8F8F;
      text-indent: 15px;
    }
  }

  &__input-label {
    margin: 0;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #8f8f8f;
  }

  .form-btns {
    justify-content: flex-end;

    &__cancel {
      margin-right: 16px;
    }
  }
}

.modal-mailer {
  display: flex;
  flex-direction: column;
  width: 1150px;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  @media(max-width: 990px) {
    height: 100vh;
    margin: 0;
  }

  .modal-content {
    position: static;
    border: none;
    border-radius: 0;
  }

  .form-btns {
    margin-top: 0;

    &__submit {
      width: 232px;

      @media(max-width: 768px) {
        margin: 0;
        width: 100%;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 24px;
    background-color: #0C3B73;

    @media(max-width: 768px) {
      display: none;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    &:before {
      content: '';
      display: block;
      width: 70px;
      height: 24px;
      margin-right: $m-x16;
      background-image: url('/assets/img/mailer-icon.svg');
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transform: translate(0, -50%);
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: #fff;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #EB5757;
        transition: all .3s;
      }
    }

    @media(max-width: 768px) {
      position: relative;
      top: 0;
      right: 0;
      transform: none;
    }
  }

  &__mobile-header {
    width: 100%;
    max-height: 3em;
    padding: .5em 1em;
    position: relative;
    z-index: 1000;
    display: none;
    justify-content: space-between;
    align-items: center;
    background-color: $c-blue;

    @media(max-width: 768px) {
      display: flex;
    }
  }

  &__back-btn {
    width: 16px;
    height: 32px;
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    border: none;
    background: transparent;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      border-bottom: 3px solid $c-white;
      border-left: 3px solid $c-white;
      transform: translate(-25%, -50%) rotate(45deg);
      transition: all .3s;
    }

    &:hover {
      background-color: $c-blue;
      transition: all .3s;
      &:after {
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
        transition: all .3s;
      }
    }
  }

  &__header-title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: $c-text-white;
  }

  &__wrapper {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  &__sidebar {
    width: 200px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: #0C3B73;

    .form-btns {
      width: 100%;
      margin-top: 0;
      padding: 0 20px;

      &__submit {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__sidebar-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }

  &__sidebar-item {
    width: 100%;
    padding: 20px 0 30px;
    border-bottom: 1px solid #fff;
    &:last-child {
      border-bottom: none;
    }
  }

  &__sidebar-title {
    margin: 0;
    margin-bottom: $m-x16;
    padding: 0 20px;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: $c-orange;
    text-transform: uppercase;
  }

  &__sidebar-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      min-height: 26px;
      padding: 5px 20px;
      background-color: transparent;
      font-size: 14px;
      line-height: 16px;
      color: #ffff;
      text-transform: uppercase;
      cursor: pointer;
      transition: all .3s;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $c-orange;
        transition: all .3s;
      }
      &.active,
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        transition: all .3s;
        &:before {
          width: 4px;
          transition: all .3s;
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 24px 24px;

    @media(max-width: 990px) {
      padding: 1em 1em 3em 1em;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: $m-x32;
  }

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 35px;
    margin-bottom: $m-x16;
    border-bottom: 1px solid #CACACA;
    transition: all .3s;

    &.error {
      border-bottom: 1px solid #EB5757;
      transition: all .3s;
    }

    @media(max-width: 990px) {
      &-label {
        margin-right: 1.3em !important;
        max-width: 1.5em;
      }

      &:nth-child(3) {
        .modal-mailer__input-label {
          margin-right: 1em !important;
          max-width: 3.8em;
        }
      }
    }

    &--focused {
      border-bottom: 1px solid $c-blue;
      transition: all .3s;
    }

    [type=text] {
      width: calc(100% - 70px);
      height: 33px;
      border: none;
      font-size: 14px;
      line-height: 31px;
      color: $c-blue;
      text-indent: 10px;
    }

    &--textarea {
      height: auto;
      margin-bottom: 0;
      border-bottom: none;

      textarea {
        width: 100%;
        height: 400px;
        padding: 10px 20px 32px;
        border: 1px solid #CACACA;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #8F8F8F;
        resize: vertical;

        &[disabled] {
          overflow: hidden;
          overflow-y: scroll;
        }

        @media(max-width: 990px) {
          padding: .75em 1em 1.5em 1em;
          background: #ffffff;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__input-label {
    width: 70px;
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #8f8f8f;
  }

  &__email-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:last-of-type {
        flex-grow: 2;
        margin-left: 3px;
      }
    }
  }

  &__email-item {
    position: relative;
    display: inline-block;
    width: auto;
    height: 28px;
    margin: 5px 3px;
    padding: 5px 30px 5px 13px;
    border-radius: 14px;
    background-color: #e5e5e5;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    color: $c-blue;

    span {
      position: absolute;
      top: 50%;
      right: 10px;
      display: block;
      width: 15px;
      height: 15px;
      cursor: pointer;
      transform: translate(0, -50%);
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 9px;
        height: 1px;
        background-color: $c-blue;
        transition: all .3s;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:hover {
        &:before,
        &:after {
          background-color: #EB5757;
        }
      }
    }
  }

  &__autocomplit {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    left: 70px;
    width: 305px;
    height: auto;
    margin: 0;
    padding: 0;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 2;
  }

  &__autocomplit-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 $p-x16;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    font-size: 14px;
    line-height: 14px;
    color: #bdbdbd;
    cursor: pointer;
    transition: all .3s;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f9f9f9;
      color: #154C8E;
      transition: all .3s;
    }
  }
}

.modal-invoices {
  display: flex;
  flex-direction: column;
  width: 1150px;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  &--fprice {
    overflow: visible !important;
  }

  &--additional {
    .modal-invoices__field {
      position: relative;
    }
  }

  .modal-content {
    position: static;
    border: none;
    border-radius: 0;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 24px;
    background-color: #0C3B73;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transform: translateY(-50%);
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: #fff;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #EB5757;
        transition: all .3s;
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: auto;
    padding: $p-x24 $p-x32 $p-x32;
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    width: 100%;
    padding-top: $p-x24;
    border-bottom: 1px solid #CACACA;
    &:first-child {
      padding-top: 0;
    }

    .modal-invoices__field {
      &:first-child {
        max-width: 104px;
      }
      &:last-child {
        max-width: 74px;
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      border: 1px solid #CACACA;
      border-radius: 4px;
      background-color: #fff;
      font-size: 14px;
      color: $c-blue;
      transition: all .3s;
      &:focus {
        border: 1px solid $c-blue;
        transition: all .3s;
      }

      &[disabled] {
        border: 1px solid transparent;
        background-color: #DFEDFF;
        transition: all .3s;
      }

      &.error {
        border: 1px solid #EB5757;
        background-color: rgba(235, 87, 87, 0.08);
        transition: all .3s;
      }
    }

    input {
      line-height: 38px;
      text-indent: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    textarea {
      padding: 8px 10px;
      line-height: 16px;
      resize: vertical;
      overflow: hidden;
    }

    &--actions {
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 13px;
    }
  }

  &__field-label {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #8f8f8f;
  }

  .modal-btn {
    margin-top: 19px;
  }

  &__positions {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__position {
    display: flex;
    width: 100%;
    margin-bottom: $m-x24;

    .modal-invoices__field {
      max-width: 114px;
      &:first-child {
        max-width: 100%;
      }
      &:last-child {
        max-width: 60px;
      }
    }
  }

  &__position-del,
  &__position-add {
    position: relative;
    width: 16px;
    height: 16px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .3s;
    &:first-of-type {
      margin-right: $m-x16;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transition: all .3s;
    }
    &:hover {
      transition: all .3s;
      &:before,
      &:after {
        transition: all .3s;
      }
    }
  }

  &__position-del {
    &:before {
      width: 100%;
      height: 100%;
      background-image: url('/assets/img/icon-del.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &:before {
        background-image: url('/assets/img/icon-del-hover.svg');
      }
    }
  }

  &__position-add {
    &:before,
    &:after {
      width: 11px;
      height: 3px;
      background-color: #808080;
    }
    &:before {
      transform: translate(-50%, -50%);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: $c-orange;
      }
    }
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: $m-x24;
  }

  &__add-invoice {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    color: $c-orange;
    text-transform: none;
    text-align: center;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
      color: #fff;
      transition: all .3s;

      i {
        background-image: url('/assets/img/icon-add-role-white.svg');
        transition: all .3s;
      }
    }

    i {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      background-image: url('/assets/img/icon-add-role.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      transition: all .3s;
    }
  }

  .form-btns {
    margin: 0;

    &__submit,
    &__cancel {
      width: 220px;
    }
  }
}
