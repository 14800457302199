// fonts
$f-stack: 'Roboto', sans-serif;
// eof fonts

// colors
$c-blue: #0c3b73;
$c-white: #fff;
$c-orange: #ee7001;
$c-text-white: #f2f2f2;
$c-warning-red: #eb5757;
$c-bg-grey: #8f8f8f;
$c-bg-light-green: #75d579;
$c-bg-light-orange: #eb8c57;
// eof colors

// margins
$m-x8: 8px;
$m-x16: 16px;
$m-x24: 24px;
$m-x32: 32px;
$m-x40: 40px;
$m-x48: 48px;
$m-x56: 56px;
$m-x64: 64px;
$m-x72: 72px;
$m-x80: 80px;

// eof margins

// paddings
$p-x8: 8px;
$p-x16: 16px;
$p-x24: 24px;
$p-x32: 32px;
$p-x40: 40px;
$p-x48: 48px;
$p-x56: 56px;
$p-x64: 64px;
$p-x72: 72px;
$p-x80: 80px;

//eof paddings
