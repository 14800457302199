button,
input,
a {
  &:focus {
    outline: none;
  }
}

.modal-btn {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-left: $m-x8;
  border: none;
  border-radius: 50%;
  background-color: $c-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all .3s;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $c-orange;
    transition: all .3s;
  }

  &--del {
    &:after {
      background-image: url('/assets/img/icon-del-orange.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-del-white.svg');
    }
  }

  &--copy {
    &:after {
      background-image: url('/assets/img/icon-copy.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-copy-white.svg');
    }
  }

  &--edit {
    &:after {
      background-image: url('/assets/img/icon-edit-orange.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-edit-white.svg');
    }
  }

  &--del-blue {
    &:after {
      background-image: url('/assets/img/icon-del-blue.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-del-white.svg');
    }
  }

  &--copy-blue {
    &:after {
      background-image: url('/assets/img/icon-copy-blue.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-copy-white.svg');
    }
  }

  &--edit-blue {
    &:after {
      background-image: url('/assets/img/icon-edit-hover.svg');
    }
    &:hover:after {
      background-image: url('/assets/img/icon-edit-white.svg');
    }
  }

  &--cancel {
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: $c-orange;
      background-image: none;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #fff;
        transition: all .3s;
      }
    }
  }
}

.modal-close {
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 70px;
  border: none;
  background-color: transparent;
  background-image: url('/assets/img/close-btn-bg.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  transform: translate(0, -50%);
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-right: 3px solid $c-white;
    border-bottom: 3px solid $c-white;
    border-radius: 1.5px;
    transform: translate(-25%, -50%) rotate(-45deg);
    transition: all .3s;
  }
  &:hover:after {
    border-right: 3px solid $c-orange;
    border-bottom: 3px solid $c-orange;
    transition: all .3s;
  }

  &--invert {
    background-image: url('/assets/img/close-btn-bg-invert.svg');
    &:hover:after {
      border-right: 3px solid $c-blue;
      border-bottom: 3px solid $c-blue;
    }
  }
}

.toggle-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  width: 80px;
  height: 28px;
  padding: 0;
  border: none;
  border-radius: 14px;
  background-color: #cacaca;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: all .3s;

  &__wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    transform: translate(-65%, 0);
    transition: all .5s;
  }

  &__content {
    flex-shrink: 0;
    display: block;
    width: calc(100% - 32px);
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: $c-white;
    text-transform: uppercase;
  }

  &__icon {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 $m-x8;
    border: 2px solid $c-white;
    border-radius: 50%;
    background-color: #cacaca;
    transition: all .3s;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $c-white;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
  }

  &--active {
    background-color: #75d579;
    transition: all .3s;

    .toggle-btn__wrapper {
      transform: translate(0, 0);
      transition: all .5s;
    }

    .toggle-btn__icon {
      background-color: #75d579;
      transition: all .3s;
      &:before {
        opacity: 1;
        transition: all .3s;
      }
    }
  }
}
