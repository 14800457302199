.form-btns {
  display: flex;
  align-items: center;
  margin-top: $m-x40;

  &__submit,
  &__cancel,
  &__create {
    display: inline-block;
    width: 132px;
    height: 40px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all .3s;
    &:hover {
      transition: all .3s;
    }
  }

  &__submit {
    margin-right: $m-x16;
    background-color: $c-orange;
    color: $c-white;
    &:hover {
      background-color: $c-blue;
    }

    &[disabled] {
      background-color: #CACACA;
    }
  }

  &__cancel {
    background-color: $c-white;
    color: #8F8F8F;
    &:hover {
      background-color: #E5E5E5;
      color: $c-white;
    }

    &[disabled] {
      background-color: #CACACA;
      color: $c-white;
    }
  }

  &__create {
    background-color: $c-blue;
    color: $c-white;
    &:hover {
      background-color: #fff;
      color: $c-blue;
    }

    &[disabled] {
      background-color: #CACACA;
    }
  }
}
