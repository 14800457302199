.fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &__label {
    margin: 0 0 8px;
    font-size: 14px;
    line-height: 1.14;
    user-select: none;
  }
}

.input {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  line-height: 40;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  transition: all .3s;

  &:active,
  &:focus {
    border-color: $c-blue;
    outline: none;
  }
}
