body {
  font-family: $f-stack;

  *, *:focus, *:active {
    outline: none !important;
  }
}

.main {
  position: relative;
  width: calc(100% - 260px);
  height: auto;
  min-height: 100vh;
  margin-left: 260px;
  padding: $p-x32;
  background-color: #fff;
  overflow-x: hidden;
  z-index: 2;
  transition: all .5s;

  &--full {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: all .5s;

    &_mobile {
      @media(max-width: 768px) {
        width: 100%;
        margin: 0;
        padding: 0 0 $p-x32 0;
      }
    }
  }

  &.main.scroll-disabled {
    position: fixed;
    overflow-y: hidden;
  }
}

.tooltip {
  .tooltip-inner {
    background-color: #808080;
  }

  .arrow {
    &:before {
      display: none !important;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border: 8px solid transparent;
    }
  }

  &-left {
    .arrow:after {
      top: 50%;
      right: 0;
      border-left: 8px solid #808080;
      transform: translate(50%, -50%);
    }
  }

  &-right {
    .arrow:after {
      top: 50%;
      left: 0;
      border-right: 8px solid #808080;
      transform: translate(-50%, -50%);
    }
  }

  &-top {
    .arrow:after {
      top: 0;
      left: 50%;
      border-top: 8px solid #808080;
      transform: translate(0, 0);
    }
  }

  &-bottom {
    .arrow:after {
      bottom: 0;
      left: 50%;
      border-bottom: 8px solid #808080;
      transform: translate(-50%, 0);
    }
  }
}

.label {
  font-size: 14px;
  display: inline-block;
  color: #c4c4c4;
}

.save-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #c4c4c4;

  .button {
    padding: 0 65px;
    height: 40px;
    line-height: 40px;
  }
}

[type=text],
[type=password] {
  &.error {
    border: 1px solid #ff5a5a !important;
  }
}

textarea,
[type=text],
[type=password] {
  &:focus {
    border: 1px solid #154C8E !important;
    box-shadow: none;
    transition: all .3s;
  }
  &[disabled] {
    border: 1px solid transparent;
    background-color: #DFEDFF;
    transition: all .3s;
  }
}

.invalid-text {
  position: absolute;
  bottom: -22px;
  left: 5px;

  span {
    font-style: italic;
    font-size: 11px;
    line-height: 13px;
    color: #eb5757;
  }
}

.edit-flight__tinput-wrapper {
  .invalid-text {
    //display: inline-block;
    //top: -8px;
    //bottom: auto;
    //left: -15px;
    //width: 100%;
    //height: auto;
    //margin: 0 2px;
    //padding: $p-x8 $p-x16;
    //border-radius: 4px;
    //background-color: #EB5757;
    //font-style: italic;
    //font-size: 12px;
    //line-height: 14px;
    //color: #fff;
    //transform: translate(0, -100%);

    &:after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 20px;
      width: 10px;
      height: 10px;
      background-color: #EB5757;
      transform: translate(0, 50%) rotate(45deg);
    }

    span {
      color: #fff;
      word-break: break-word;
    }
  }
}

.edit-flight__tinput-error,
.edit-quote__tinput-error {
  .invalid-text {
    position: static;

    span {
      color: #fff;
      word-break: break-word;
    }
  }
}

.edit-flight__tab,
.op-create__tabs-body-form-input,
.company__tabs-body-form-input,
.edit-quote__field,
.modal-invoices__field,
.edit-flight__field,
.edit-crew {
  .invalid-text {
    position: static;
    margin-top: 4px;
  }
}

.edit-flight__field {
  .invalid-text {
    position: static;
    margin-top: 4px;

    span {
      display: block;
    }
  }
}

.edit-quote__input-wrapper {
  .invalid-text {
    left: 0;
  }
}

.ngx-select:not(
.ngx-select_multiple,
.table-footer__showing-select
) {
  .ngx-select__toggle {
    transition: all .3s;

    @media(max-width: 768px) {
      background-color: #f9f9f9 !important;
    }
  }

  .ngx-select__choices {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 205px;
    margin: 0;
    padding: 0;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow: auto;
    z-index: 2;

    li {
      flex-shrink: 0;
      width: 100%;
      height: 41px;
      padding: 0;
      border-bottom: 1px solid #e0e0e0;
      background-color: #fff;
      font-size: 14px;
      line-height: 16px;
      color: #bdbdbd;
      cursor: pointer;
      transition: all .3s;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #f9f9f9;
        color: #154C8E;
        transition: all .3s;

        .ngx-select__item {
          color: #154C8E;
          background-color: transparent;
        }
      }
    }

    .ngx-select__item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 41px;
      padding: 0 $p-x16;
      color: #bdbdbd;
      &:hover {
        color: #154C8E;
        background-color: transparent;
      }

      span {
        display: block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ngx-select__item_active {
      background-color: transparent;
      color: #154C8E;
      transition: all .3s;
    }
  }
}

ngx-select.error .ngx-select__toggle {
  border-color: #eb5757;
  transition: all .3s;
}

.edit-quote .ngx-select__clear-icon {
  display: none !important;
}

.edit-quote,
.filter-modal,
.edit-crew {
  .ngx-select_multiple {
    .ngx-select__selected {
      .ngx-select__selected-plural {
        padding: 6px 12px;
        border: none;
        border-radius: 14px;
        background-color: #DFEDFF;
        box-shadow: none;
        font-size: 14px;
        color: $c-blue;
        white-space: normal;
        text-align: left;
        outline: none;
        &:active {
          background-color: #DFEDFF;
          box-shadow: none;
          color: $c-blue;
          &:focus {
            box-shadow: none;
          }
        }
        &:focus {
          box-shadow: none;
        }
      }

      .ngx-select__clear {
        opacity: 1 !important;
      }

      .ngx-select__clear-icon {
        width: 10px;
        height: 10px;
        color: #0C3B73;
        &:before,
        &:after {
          border-top: 1px solid;
        }
        &:hover {
          color: #EB5757;
        }
      }
    }

    .ngx-select__search {
      height: 32px !important;
      border: none !important;
    }

    .ngx-select__choices {
      max-height: 220px;
      padding: 0 $p-x16;

      li {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding: $p-x16 $p-x8;
        border-bottom: 1px solid #e0e0e0;
        background-color: #fff;
        font-size: 14px;
        line-height: 14px;
        color: #bdbdbd;
        cursor: pointer;
        transition: all .3s;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: #f9f9f9;
          color: #154C8E;
          transition: all .3s;
        }
      }

      .ngx-select__item {
        padding: 0;
        color: #bdbdbd;
        &:hover {
          color: #154C8E;
          background-color: transparent;
        }

        span {
          display: inline-flex;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
          white-space: normal;
        }
      }

      .ngx-select__item_active {
        background-color: transparent;
        color: #154C8E;
        outline: 0;
      }
    }
  }
}

.edit-crew {
  .ngx-select_multiple {
    display: flex;
    align-items: center;
    width: 100%;

    .ngx-select__selected {
      display: inline-flex;
      align-items: center;
      width: auto;
    }

    .ngx-select__search {
      flex-grow: 1;
      width: 100%;
    }
  }
}

.filter-modal {
  position: fixed;
  top: 0;
  right: -543px;
  width: 543px;
  height: 100vh;
  padding-top: $p-x40;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 101;
  transition: all .3s ease-in-out;

  &--open {
    right: 0;
    transition: all .3s ease-in-out;
  }

  ngx-select,
  .ngx-select,
  .ngx-select__selected {
    width: 100%;
  }

  .md-drppicker.double {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 490px;
    height: 300px;
    padding: 20px 20px 20px 10px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .ranges {
      flex-shrink: 0;
      width: 80px;
      height: calc(100% - 55px);
      margin: 0;
      margin-right: 15px;

      ul {
        width: 100%;
      }

      li {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: transparent;
        }
      }

      button {
        display: inline-block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        &.active,
        &:hover {
          background-color: transparent;
          color: #0C3B73;
          transition: all .3s;
        }
      }
    }

    .calendar {
      flex-shrink: 0;
      width: calc(50% - 58px);
      height: calc(100% - 55px);
      margin: 0;

      &.left {
        margin-right: 20px;
      }

      .calendar-table {
        width: 100%;
        height: 100%;
        padding: 0 !important;
        border: none;
        border-radius: 0;
        background-color: transparent;

        table {
          width: 100%;
          height: 100%;
        }

        thead,
        tbody,
        tr {
          width: 100%;
        }

        .month {
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #8f8f8f;
        }

        .prev,
        .next {
          position: relative;
          background-image: none;
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 7px;
            height: 7px;
            border-bottom: 2px solid #8f8f8f;
            border-left: 2px solid #8f8f8f;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all .3s;
          }
        }

        td, th {
          width: 24px;
          min-width: 24px;
          height: 24px;
          margin: 0;
          padding: 0;
          color: #8f8f8f;
          text-align: center;
          vertical-align: middle;
          transition: all .3s;
        }

        td {
          font-size: 14px;
          line-height: 14px;
        }

        th {
          font-size: 12px;
          line-height: 12px;
        }

        .off {
          color: #CACACA;
          pointer-events: none;
        }

        td.active {
          color: $c-white;
          transition: all .3s;
        }

        .in-range {
          background-color: #E0E0E0;
          transition: all .3s;
        }

        .start-date,
        .end-date {
          background-color: $c-orange;
        }

        .start-date {
          border-radius: 6px 0 0 4px;
        }

        .end-date {
          border-radius: 0 4px 4px 0;
        }

        .start-date.end-date {
          border-radius: 6px 4px 4px 4px;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 100%;
      height: 40px;
      margin: 0;
      margin-top: 15px;

      .buttons_input {
        display: flex;
        align-items: center;
      }

      .clear {
        svg {
          color: #eb3232;
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 40px;
        margin: 0 8px;
        padding: 0;
        border: none;
        border-radius: 4px;
        background-color: $c-orange;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: $c-white;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        &:hover {
          background-color: $c-blue;
          transition: all .3s;
        }
      }
    }
  }
}

.text-size12 {
  font-size: 12px;
}

.custom-multiselect {
  border: 1px solid #CACACA;
  border-radius: 4px;
  background: #F9F9F9;

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-right: 8px;
    padding-bottom: 8px;
    list-style: none;
  }

  &__item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin: 8px 0 0 8px;
    padding: 6px 12px;
    border: none;
    border-radius: 14px;
    background-color: #DFEDFF;
    box-shadow: none;
    font-size: 14px;
    color: $c-blue;
    white-space: normal;
    word-break: break-word;
    text-align: left;
    outline: none;
    &:active {
      background-color: #DFEDFF;
      box-shadow: none;
      color: $c-blue;
      &:focus {
        box-shadow: none;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }

  &__item-close {
    position: relative;
    width: 15px;
    height: 15px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 10px;
      height: 1px;
      background-color: #0C3B73;
      transition: all .3s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #EB5757;
      }
    }
  }

  &__item-input {
    display: inline-flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-top: 8px;
    margin-left: 8px;

    input[type=text] {
      width: 100% !important;
      height: 32px !important;
      border: none !important;
    }
  }
}

button:not(.edit-flight__tab-btn) {
  &[disabled] {
    background-color: #BFC8D9 !important;
    color: #626C7D !important;
    pointer-events: none !important;
    transition: all .3s;
  }
}

.overflow--hidden {
  overflow: hidden;
}
