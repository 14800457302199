/* You can add global styles to this file, and also import other style files */

@import "assets/scss/vendor/vendor";

@import "assets/scss/helpers/variables";

@import "assets/scss/common/general";
@import "assets/scss/common/buttons";
@import "assets/scss/common/icons";
@import "assets/scss/common/inputs";
@import "assets/scss/common/alerts";
@import "assets/scss/blocks/table-header";
@import "assets/scss/blocks/table";
@import "assets/scss/blocks/table-footer";
@import "assets/scss/blocks/form-btns";
